import {LoadingPrimary} from '@sharefiledev/flow-web';
import {OverlayDrawerComponentProps, PiletApi} from '@sharefiledev/sharefile-appshell';
import {Skeleton} from 'antd';
import React from 'react';

import {
    ConnectedEntityAutocompleteExtensionParams,
    piletOverlays,
    ShareButtonExtensionParams,
    TaskDrawerParams,
    VerticalizationParams,
} from '../sdk/extensionTypes';

export type ExtensionImplementationProps<TParams> = TParams & {piletApi: PiletApi};

export type TaskDrawerProps = VerticalizationParams &
    ExtensionImplementationProps<TaskDrawerParams> &
    Pick<
        OverlayDrawerComponentProps<typeof piletOverlays.informationRequest>,
        'completeInteraction' | 'cancelInteraction'
    >;

export type ClientTaskDrawerProps = ExtensionImplementationProps<{containerRID: string}> &
    Pick<
        OverlayDrawerComponentProps<typeof piletOverlays.clientInformationRequest>,
        'completeInteraction' | 'cancelInteraction' | 'updateDrawerProps'
    >;

export type ShareButtonProps = ExtensionImplementationProps<ShareButtonExtensionParams>;

const LazyRequestViewDrawer = React.lazy(() => import('./RequestViewDrawer'));
export const AsyncRequestViewDrawer: React.FC<TaskDrawerProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyRequestViewDrawer {...props} />
        </React.Suspense>
    );
};

const LazyRequestClientView = React.lazy(() => import('./RequestClientViewDrawer'));
export const AsyncRequestClientView: React.FC<ClientTaskDrawerProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyRequestClientView {...props} />
        </React.Suspense>
    );
};

const LazyShareButtonExtensionSlot = React.lazy(() => import('./ShareButton'));
export const AsyncShareButtonExtensionSlot: React.FC<ShareButtonProps> = (props) => {
    return (
        <React.Suspense fallback={<Skeleton />}>
            <LazyShareButtonExtensionSlot {...props} />
        </React.Suspense>
    );
};

const LazyConnectedEntityAutoCompleteExtensionSlot = React.lazy(
    () => import('../components/ConnectedEntityAutoComplete/ConnectedEntityAutoComplete'),
);
export const AsyncConnectedEntityAutoCompleteExtensionSlot: React.FC<
    ConnectedEntityAutocompleteExtensionParams
> = (props) => {
    const {
        placeholder,
        size,
        connectedEntityRID,
        defaultValue,
        style,
        onSelect,
        onBlur,
        onError,
        onClear,
    } = props;
    return (
        <React.Suspense fallback={<LoadingPrimary />}>
            <LazyConnectedEntityAutoCompleteExtensionSlot
                placeholder={placeholder}
                size={size}
                onSelect={onSelect}
                onBlur={onBlur}
                onError={onError}
                onClear={onClear}
                defaultValue={defaultValue}
                connectedEntityRID={connectedEntityRID}
                style={style}
            />
        </React.Suspense>
    );
};
