import {PiletApi} from '@sharefiledev/sharefile-appshell';
import {NotificationInstance} from 'antd/es/notification/interface';

export enum StatusBadgeOptions {
    Expired = 'expired',
    Completed = 'completed',
    InProgress = 'in progress',
    Closed = 'closed',
    Draft = 'draft',
    AssignedToYou = 'assigned to you',
    CompletedByYou = 'completed by you',
}

export enum StatusBadgeOptionsClient {
    Completed = 'completed',
    Open = 'pending',
    Draft = 'draft',
}

export enum ToastNotificationType {
    INFO = 'Info',
    SUCCESS = 'Success',
    ERROR = 'Error',
    WARNING = 'Warning',
}

export type AssigneeDetails = {
    name: string;
    email: string;
    responseStatus: string;
};

export type InformationRequestAssignee = Record<string, AssigneeDetails>;

export type ClientUsers = {
    label: string; // user's FullName
    value: string; // user's Id
};

export const dateFormat = 'MM/DD/YYYY'; // format used on UI
export const dateFormatFull = 'YYYY-MM-DDTHH:mm:ss'; // format used to send on payload

export type User = {
    FullName: string;
    Email: string;
    Id: string;
    Roles: string[];
};

export type InformationRequest = {
    containerID: string;
    informationRequestRID: string;
    description?: string;
    name: string;
    informationRequestStatus: StatusBadgeOptions;
    dueDate?: string | null;
    assigneeDetails?: InformationRequestAssignee;
    totalAssigned: number;
    totalSubmitted: number;
    deletedOn?: string;
    informationRequestSchema: InformationRequestSchema;
    thankYouMessage: string | null;
    permissions?: Record<string, boolean>;
    lifeCycleStage?: InfoReqLifeCycleStage;
};

export enum InfoReqLifeCycleStage {
    Active = 'Active',
    Deleted = 'Deleted',
    Frozen = 'Frozen',
}

export type InformationRequestSchema = {
    fields: any[];
    layout: any;
};

export interface ClientInformationRequest {
    informationRequestRID: string;
    name: string;
    description: string;
    dueDate: string;
    responseStatus: StatusBadgeOptionsClient;
    lifeCycleStage?: InfoReqLifeCycleStage;
}

export interface InformationResponsePayload {
    containerRID: string;
    response: any;
    isDraft?: boolean;
}

export enum RenderMode {
    Full = 'full',
    SpecEdit = 'spec-edit',
}

export interface PageProps {
    piletApi: PiletApi;
}

export type SubmitHandler = {
    notificationApi: NotificationInstance;
    customSuccessMessage?: string | React.ReactNode;
    customErrorMessage?: string | React.ReactNode;
    onHandleSubmit: (result: Promise<void>) => Promise<void>;
};

export type ViewType = 'mini' | 'full';

export const piletKey = 'information_requests';

export enum InformationRequestEntityType {
    requestlist = 'requestlist',
    informationRequest = 'inforeq',
}

export const InformationRequestPiletEvents = {
    created: 'urn:event:resource:created',
    deleted: 'urn:event:resource:deleted',
    updated: 'urn:event:resource:updated',
};

export type InformationRequestSpec = {
    name: string;
    description?: string;
    dueDate?: string;
    informationRequestSchema: InformationRequestSchema;
    thankYouMessage: string | null;
    assigneeIds?: string[];
    message?: string;
    follow?: boolean;
};
