import React, {createContext, useContext} from 'react';

import {defaultDataProvider} from './defaultDataProvider';
import {DataProvider} from './types';

const Context = createContext<DataProvider>(defaultDataProvider);

export interface DataProviderContextProviderProps {
    children: React.ReactNode;
    dataProvider?: DataProvider;
}

export const DataProviderContextProvider = ({
    dataProvider,
    children,
}: DataProviderContextProviderProps) => (
    <Context.Provider value={dataProvider ?? defaultDataProvider}>{children}</Context.Provider>
);

export const useDataProviderContext = () => useContext(Context);
