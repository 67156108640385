import {Skeleton, Spin} from 'antd';
import React from 'react';

import {CreateFromBlankDrawerProps} from '../../components/CreateInformationRequestDrawer/CreateFromBlank';
import {CommonExtensionProps, InformationRequestListBlockParams} from './types';

const LazyLoadInformationRequestListBlock = React.lazy(
    () => import('./InformationRequestListBlock'),
);
export const AsyncInformationRequestListBlock: React.FC<
    CommonExtensionProps & InformationRequestListBlockParams
> = (props) => (
    <React.Suspense fallback={<Spin />}>
        <LazyLoadInformationRequestListBlock {...props} />
    </React.Suspense>
);

const LazyLoadCreateInformationRequestDrawerBlank = React.lazy(
    () => import('../../components/CreateInformationRequestDrawer/CreateFromBlank'),
);
export const AsyncCreateInformationRequestDrawerBlank: React.FC<CreateFromBlankDrawerProps> = (
    props,
) => (
    <React.Suspense fallback={<Skeleton />}>
        <LazyLoadCreateInformationRequestDrawerBlank {...props} />
    </React.Suspense>
);
