import axios from 'axios';

import {API_PATH} from '../config/constants';

const baseUrl = () => {
    // NOTE: use for local debugging 'http://localhost:8080';
    return window.origin;
};

const ApiClient = axios.create({
    baseURL: `${baseUrl()}${API_PATH}`,
    headers: {
        'X-BFF-CSRF': 'true',
    },
});

export default ApiClient;
